export default {
    notifs: state => {
        return state.notifs;
    },
    unreadNotifsCount: (state, getters) => {
        return getters.unreadNotifs.length;
    },
    unreadNotifs: state => {
        return state.notifs?.filter(i => !i.read) ?? [];
    },
    readNotifsCount: (state, getters) => {
        return getters.readNotifs.length;
    },
    readNotifs: state => {
        return state.notifs?.filter(i => i.read) ?? [];
    }
}