import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getAppVersion() {
        return window.axios.get(this.baseUrl + 'api/version?label=pwa-merciplus')
    },

    subscribe(subscription) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }

        return window.axios.post(this.baseUrl + `api-m/einstein/subscribe`, {subscription}, config);
    },
}
