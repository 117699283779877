<template>
    <div id="layout_footer">
        <nav id="mobile-footer-nav" class="navbar fixed-bottom bg-white p-0">
            <ul class="nav nav-pills  nav-fill w-100">
                <li class="nav-item" :class="{ 'selected': (selected === 'home') }">
                    <a href="#" @click="goTo('home')" class="nav-link home"><i class="fas fa-home"></i><span>Accueil</span></a>
                </li>
                <li class="nav-item" :class="{ 'selected': (selected === 'pointages') }">
                    <a class="nav-link pointages declaration-prestation debut-intervention" @click="goTo('pointages')"><i
                            class="fas fa-check-circle"></i><span>Pointages</span></a>
                </li>
                <li class="nav-item" :class="{ 'selected': (selected === 'agenda') }">
                    <a @click="goTo('agenda')" class="nav-link agenda"><i class="fas fa-calendar-alt"></i><span>Agenda</span></a>
                </li>
                <li class="nav-item" :class="{ 'selected': (selected === 'clients') }">
                    <a @click="goTo('clients')" class="nav-link clients fiche-client fiche-mission"><i
                            class="fas fa-users"></i><span>Clients</span></a>
                </li>
                <!--<li class="nav-item disabled" :class="{ 'selected': (selected === 'infos') }">
                    <a class="nav-link infos" @click="goTo('infos')"><i class="fas fa-info-circle"></i><span>Infos
          <small class="position-absolute badge-notification-navbar"><span
                  class="badge badge-primary" v-if="nbInfos > 0">{{ nbInfos }}</span></small></span></a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link menu cursor-pointer" href="#" @click="displayMenu"><i
                        class="fas fa-ellipsis-h"></i><span>Menu
                        <small
                            class="position-absolute badge-notification-navbar"><span
                            style="font-size:14px"
                            class="badge badge-danger" v-if="unreadNotifsCount > 0">{{ unreadNotifsCount }}</span>
                        </small>
                    </span></a>
                </li>
            </ul>
        </nav>

<!--        <nav id="desktop-footer-nav" class="navbar fixed-bottom navbar-light bg-blue-footer text-center text-center text-white d-none d-lg-block">-->
<!--            <div class="w-100 text-center">-->
<!--                <h5 class="m-0 font-weight-bold">MERCI +</h5>-->
<!--                <i class="fas fa-phone"></i> 03 68 100 440-->
<!--            </div>-->
<!--        </nav>-->
    </div>
</template>
<script>
import router from '../../router'
import {mapGetters} from 'vuex'


export default {
    name: 'Footer',
    props: {
        selected: {
            default: ''
        }
    },
    data() {
        return {
            nbInfos: 0
        }
    },
    computed: {
        ...mapGetters({
            unreadNotifsCount: 'unreadNotifsCount'
        })
    },
    methods: {
        displayMenu(event) {
            event.preventDefault()
            this.$emit('displayMenu')
        },
        goTo(name) {
            router.push({name: name})
            this.$emit('goTo')
        }
    }
}
</script>
<style>
    .selected a {
        color: #FFF !important;
        cursor: not-allowed;
        pointer-events: none;
    }
    .disabled a {
        color: black !important;
    }
</style>
