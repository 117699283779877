<template>
    <div class="menu-offcanvas-wrapper">
        <b-container fluid class="inner pb-2">
            <b-row class="justify-content-end text-white">
                <b-col sm="12" class="bg-primary pt-3 pb-3 text-dark ">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-auto" v-if="user">{{ user.prenom }} {{ user.nom }}<br/><span class="text-muted">N° {{ user.idutilisateur }}</span>
                        </div>
                        <!--<div class="col-auto"><a @click="goTo('/')" class="text-dark"><i class="fas fa-cogs"></i>Compte</a></div>-->
                    </div>
                </b-col>
                <b-col sm="12" class="bg-white">
                    <a @click="goToObject(declarationRoute)" class="d-block pt-2 pb-2">
                        <div class="card text-white p-0"
                             :class="{ 'bg-success': declarationSuccessClass, 'bg-danger': !declarationSuccessClass }">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-center m-0">
                                    <i class="fas fa-hand-sparkles"></i> <span> {{ declarationButtonLabel }}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </b-col>
                <b-col sm="12" class="bg-white">
                    <b-list-group>
                        <a @click="goTo('home')" class="border-0 list-group-item text-dark">
                            <h6><i class="fas fa-home"></i> Accueil</h6>
                        </a>
                        <a @click="goTo('pointages')" class="border-0 list-group-item text-dark">
                            <h6><i class="fas fa-check-circle"></i> Pointages</h6>
                        </a>
                        <a @click="goTo('agenda')" class="border-0 list-group-item text-dark">
                            <h6><i class="fas fa-calendar-alt"></i> Agenda</h6>
                        </a>
                        <a @click="goTo('clients')" class="border-0 list-group-item text-dark">
                            <h6><i class="fas fa-users"></i> Clients</h6>
                        </a>
                        <a @click="goTo('infos')" class="border-0 list-group-item text-dark ">
                            <h6><i class="fas fa-info-circle"></i> Notifications
                                <small
                                    class="position-absolute badge-notification-navbar"><span
                                    style="font-size:14px"
                                    class="badge badge-danger" v-if="unreadNotifsCount > 0">{{
                                        unreadNotifsCount
                                    }}</span>
                                </small>
                            </h6>
                        </a>
                        <!--<a @click="goTo('home')" class="border-0 list-group-item text-dark disabled">
                            <h6><i class="fas fa-file-contract"></i> Mes documents</h6>
                        </a>-->
                        <a @click="disconnect" class="border-0 list-group-item text-dark">
                            <h6><i class="fas fa-backspace"></i> Se déconnecter</h6>
                        </a>
                    </b-list-group>
                </b-col>
                <b-col sm="12" class="bg-info">
                    <a class="d-block pt-3 pb-3 cursor-pointer callMerciBtn" :href="`tel:${branchPhone}`">
                        <div class="bg-white text-info">
                            <div class="card-body">
                                <h4 class="d-flex align-items-center justify-content-center m-0">
                                    <span><i class="fas fa-phone-alt mr-2"></i> Appeler {{getCompanyName()}}</span>
                                </h4>
                            </div>
                        </div>
                    </a>
                    <div class="text-center pb-2">version {{ require('../../../package.json').version }}</div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import store from '../../store'
import router from '../../router'
import pointageService from '../../services/pointageService'
import userService from "@/services/userService";
import {mapGetters} from "vuex";

export default {
    name: 'Menu',
    data() {
        return {
            user: null,
            declarationRoute: {name: 'detection'},
            declarationButtonLabel: 'Déclarer mon arrivée',
            declarationSuccessClass: true,
            branchPhone: '0368100444'
        }
    },
    computed: {
        ...mapGetters({
            unreadNotifsCount: 'unreadNotifsCount'
        })
    },
    mounted() {
        userService.getContrats()
            .then(res => {
                if (res.data.etat == "KO") return;
                const contract = res.data?.data?.[0];
                let phone = this.branchPhone;

                if (contract && contract.company_type != 'Propre' && contract.phone) {
                    phone = contract.phone;
                }

                this.branchPhone = phone;
            });
    },
    methods: {
        getCompanyName() {
            if (!location.host.includes('family')) {
                return "MERCI+"
            } else {
                return "Family Sphere"
            }
        },
        goTo(name) {
            router.push({name: name})
            this.$emit('goTo')
        },
        goToObject(route) {
            router.push(route)
            this.$emit('goTo')
        },
        disconnect() {
            store.commit('CLEAR_ALL_DATA')
            this.$emit('disconnect')
            router.go();
        },
        loadUser() {
            this.user = store.state.user;
        },
        getPointageEnCours() {
            pointageService.getPointageEnCours().then((response) => {
                if (response.data.etat === 'OK' && response.data?.data?.idintervention_ticking) {
                    this.declarationRoute = {
                        name: 'intervention',
                        params: {idIntervention: response.data.data.idintervention_ticking}
                    }
                    this.declarationButtonLabel = 'Déclarer mon départ';
                    this.declarationSuccessClass = false;
                } else {
                    this.declarationRoute = {name: 'detection'};
                    this.declarationButtonLabel = 'Déclarer mon arrivée';
                    this.declarationSuccessClass = true;
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3016', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3016',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        }
    }
}
</script>
