import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./stylesheets/main.scss";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import ToggleButton from "vue-js-toggle-button";
import Auth from "./auth";
import { VueReCaptcha } from "vue-recaptcha-v3";

import Rollbar from "rollbar";

import {
    faBackspace,
    faBroom,
    faCalendarAlt,
    faCheck,
    faCheckCircle,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faCogs,
    faEdit,
    faEllipsisH,
    faExclamationTriangle,
    faEye,
    faFileContract,
    faHandSparkles,
    faHome,
    faInfoCircle,
    faMapMarked,
    faMapMarkedAlt,
    faNewspaper,
    faPhone,
    faPhoneAlt,
    faPowerOff,
    faRecycle,
    faSearch,
    faSignInAlt,
    faSms,
    faStopCircle,
    faThumbsUp,
    faLightbulb,
    faTimes,
    faUser,
    faChild,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";

import { faWaze } from "@fortawesome/free-brands-svg-icons";

import "./registerServiceWorker";

Vue.use(VueReCaptcha, { siteKey: "6LcAdtYZAAAAAGCtNt2aD-f59srWAS4Npva6gFQv" });

// -- font awesome -- <<
library.add(
    faSignInAlt,
    faCalendarAlt,
    faHome,
    faCheckCircle,
    faUsers,
    faInfoCircle,
    faEllipsisH,
    faPhone,
    faFileContract,
    faPhoneAlt,
    faClock,
    faCheck,
    faExclamationTriangle,
    faNewspaper,
    faChevronRight,
    faBroom,
    faStopCircle,
    faEye,
    faMapMarkedAlt,
    faChevronDown,
    faChevronLeft,
    faHandSparkles,
    faEdit,
    faMapMarked,
    faLightbulb,
    faRecycle,
    faChevronCircleRight,
    faChevronCircleLeft,
    faSms,
    faCogs,
    faBackspace,
    faUser,
    faChevronUp,
    faPowerOff,
    faWaze,
    faThumbsUp,
    faTimes,
    faChild,
    faSearch
);

dom.watch();
// -- font awesome  -- >>

Vue.use(BootstrapVue);

Vue.use(ToggleButton);

const moment = require("moment");
require("moment/locale/fr");
Vue.use(require("vue-moment"), {
    moment,
});

if (!window.axios) {
    window.axios = require("axios");
}

Vue.use(Auth, {
    client_secret: "zv5rple3BbJW3yGcdpZ69dRSxz93vmre7XbAS4RY",
    client_id: 2,
});
Vue.prototype.$auth = Auth;

Vue.config.productionTip = false;

Vue.prototype.$rollbar = new Rollbar({
    accessToken: "cfa523331f354800bb68468bb8cca0b6",
    environment: process.env.VUE_APP_ENV,
    captureUncaught: true,
    captureUnhandledRejections: true,
    checkIgnore: (isUncaught, args, payload) => {
        if (
            payload.body.telemetry.every((i) => {
                return (
                    i.level === "log" ||
                    i.type === "log" ||
                    i.type === "network" ||
                    i.body?.message === "Network Error"
                );
            })
        ) {
            return true;
        }

        return payload.body.telemetry.find((i) =>
            [401, 419, 0].includes(i.body?.status_code)
        );
    },
    ignoredMessages: [/Loading.*chunk/, /Network.*error/],
});

Vue.prototype.$rollbar.configure({
    enabled:
        !location.href.includes("localhost") &&
        process.env.VUE_APP_ENV != "TEST",
    // enabled: true,
});

Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
};

Vue.prototype.$rollbar._error = Vue.prototype.$rollbar.error;
Vue.prototype.$rollbar.error = (message, error = null) => {
    const user = store.state.user ?? {};

    if (error?.message?.includes("Loading chunk")) {
        location.href = "/mise-a-jour";
        return;
    }

    console.error(message, error ?? "");

    Vue.prototype.$rollbar.configure({
        payload: {
            environment: process.env.VUE_APP_ENV,
            id: user.idutilisateur,
            message: message,
            fingerprint: JSON.stringify(message),
            useragent: navigator.userAgent,
        },
    });

    Vue.prototype.$rollbar._error(message);
};

document.title = "Espace intervenant";

new Vue({
    render: (h) => h(App),
    store,
    router,
}).$mount("#app");
