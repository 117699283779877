import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    tokens: {},

    declarerArrivee (params) {
        this.tokens['declarerArrivee']?.cancel();
        this.tokens['declarerArrivee'] = window.axios.CancelToken.source();

        let config = {
            cancelToken: this.tokens['declarerArrivee'].token,
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/einstein/pointages/declarer-arrivee', params,config)
    },
    declarerDepart (params) {
        this.tokens['declarerDepart']?.cancel();
        this.tokens['declarerDepart'] = window.axios.CancelToken.source();

        let config = {
            cancelToken: this.tokens['declarerDepart'].token,
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/einstein/pointages/declarer-depart', params, config)
    },
    declarerPointage(params) {
        this.tokens['declarerPointage']?.cancel();
        this.tokens['declarerPointage'] = window.axios.CancelToken.source();

        let config = {
            cancelToken: this.tokens['declarerPointage'].token,
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }

        return window.axios.post(this.baseUrl + 'api-m/einstein/pointages/declarer-pointage', params, config)
    },
    getDistance(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/einstein/pointages/get-distance', params, config)
    },
    createDistanceError(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/einstein/pointages/create-distance-error', params, config);
    },
    getHeuresDeclarees () {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.get(
            this.baseUrl + 'api-m/einstein/pointages/info-pointage-existant?valide=non',
            config
        )
    },
    getPointageEnCours () {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.get(
            this.baseUrl + 'api-m/einstein/pointages/en-cours',
            config
        )
    },
    getPointage (idIntervention) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.get(
            this.baseUrl + 'api-m/einstein/pointages/' + idIntervention,
            config
        )
    },
    getBusinessActivities() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.get(
            this.baseUrl + 'api-m/einstein/pointages/business-activities',
            config
        )
    }
}
