export const STORAGE_KEY = '5f3a7527bc20e'

let initialState = {}

if (localStorage.getItem(STORAGE_KEY)) {
    initialState = JSON.parse(localStorage.getItem(STORAGE_KEY))
} else {
    initialState = {
        auth: {
            isLoggedIn: false,
            accessToken: null,
            refreshToken: null
        },
        user: {
            email: null,
            idutilisateur: null,
            login: null,
            mobile: null,
            nom: null,
            prenom: null,
            usid: null
        },
        notifs: []
    }
}

export const state = initialState

