<template>
    <div id="layout_header">
        <!--Header Desktop-->
        <div id="desktop-header">
            <!--Barre settings perso-->
            <div class="bg-primary log-bar">
                <div class="d-flex justify-content-end align-content-center py-2 container">
                    <span class="mr-2"><span class="randomName" v-if="user">{{ user.prenom }} {{ user.nom }}</span> <span class="text-info font-weight-bold" v-if="user">N° {{ user.idutilisateur}}</span></span>
                    <!--<router-link to="/" class="text-dark"><i class="fas fa-cogs"></i> Compte</router-link>-->
                    <a @click="disconnect" class="border-0 text-dark cursor-pointer" v-b-popover.hover.bottom="'Se déconnecter'">
                        <i class="fas fa-power-off"></i>
                    </a>
                </div>
            </div>
            <div class="bg-white menu">
                <nav class="container">
                    <div class="align-items-center d-flex flex-md-row justify-content-between">
                        <!--<router-link class="" to="/" aria-label="Merci+">
                            <img src="/img/logo.png" alt="" />
                        </router-link>-->
                        <!--<router-link class="btn d-md-inline-block btn-success " to="declaration-prestation">
                            <i class="fas fa-hand-sparkles"></i> <span>Déclarer une prestation</span>
                        </router-link>-->
                        <router-link class="py-2 d-md-inline-block text-info home" to="/">
                            <i class="fas fa-home"></i> <span>Accueil</span>
                        </router-link>
                        <router-link class="py-2 d-md-inline-block text-info pointages declaration-prestation debut-intervention"
                           to="/pointages">
                            <i class="fas fa-check-circle"></i> <span>Pointages</span>
                        </router-link>
                        <router-link class="py-2  d-md-inline-block text-info agenda" to="/agenda">
                            <i class="far fa-calendar-alt"></i> <span>Agenda</span>
                        </router-link>
                        <router-link class="py-2 d-none d-md-inline-block text-info clients fiche-client fiche-mission" to="/clients">
                            <i class="fas fa-users"></i> <span>Clients</span>
                        </router-link>
                        <!--<router-link class="py-2 d-none d-md-inline-block text-info infos" to="#infos">
                            <i class="fas fa-info-circle"></i> <span>Infos</span>
                        </router-link>
                        <router-link class="py-2 d-none d-md-inline-block text-info documents" to="#documents">
                            <i class="fas fa-file-contract"></i> <span>Mes documents</span>
                        </router-link>-->
                    </div>
                </nav>
            </div>
        </div>
        <!--Header Mobile-->
        <div id="mobile-header">
            <nav class="bg-white fixed-top navbar p-0 justify-content-center mobile-header-navbar">
                <router-link class="navbar-brand" to="/"><img :src="getLogoUrl()"  alt="" /></router-link>
            </nav>
            <div class="navbar invisible p-0 canNaturallySetTheHeightOfTheNavbar">
                <div class="navbar-brand"><img :src="getLogoUrl()" alt="" /></div>
            </div>
        </div>
        <!--Wrapper title-->
        <div class="nav-title"></div>
    </div>
</template>
<script>
    import store from '../../store'
    import router from '../../router'
    export default {
        name: 'Header',
        data () {
            return {
                user: null
            }
        },
        mounted () {
            this.loadUser()
        },
        methods: {
            disconnect () {
                store.commit('CLEAR_ALL_DATA')
                this.$emit('disconnect')
                router.go()
            },
            loadUser () {
                this.user = store.state.user
            },
            getLogoUrl() {
                if (!location.host.includes('family')) {
                    return "/img/logo.png";
                } else {
                    return "/img/gfs_logo.png"
                }
            }
        }
    }
</script>
<style scoped>
    #desktop-header {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 2;
    }
</style>
