<template>
    <div id="app">
        <div v-if="offline"
             style="position: fixed; width: 100vw; height: 100vh; z-index: 999999999999999; background-color: rgba(0, 0, 0, 0.7)">
            <div style="background-color: white;" class="">
                <h3 style="background-color: rgba(100, 0, 0, 0.5); color: white;" class="text-center bg-danger p-2">
                    Attention
                </h3>
                <div class="p-3">
                    Aucune connexion internet, vous êtes en mode hors ligne, certaines fonctionnalités ont été
                    désactivées.
                </div>
            </div>
        </div>
        <Header ref="header" v-if="isLoggedIn" @disconnect="isLoggedIn=false"></Header>
        <keep-alive>
            <div id="components_application">
                <router-view @menu="setMenu" @login="loginHandler" @checkVersion="checkVersion"/>
            </div>
        </keep-alive>
        <Footer v-if="isLoggedIn" :selected="selectedMenu" @displayMenu="displayMenu" @goTo="showMenu=false"></Footer>
        <Menu v-if="isLoggedIn" id="sideMenu" ref="sideMenu" :class="{ 'sideMenu-shown': (showMenu) }"
              @goTo="displayMenu" @disconnect="isLoggedIn=false"></Menu>
        <AppVersionModal :version="version" ref="appVersionModal"></AppVersionModal>
    </div>
</template>
<script>
import Header from './components/Layout/Header'
import Footer from './components/Layout/Footer'
import Menu from './components/Layout/Menu'
import AppVersionModal from './components/Pages/AppVersionModal'
import appService from './services/appService'
import axios from 'axios'

export default {
    name: 'app',
    components: {AppVersionModal, Menu, Header, Footer},
    data() {
        return {
            isLoggedIn: false,
            showMenu: false,
            selectedMenu: '',
            version: '0.0.0',
            newContentReady: false,
            showAppVersionModal: false,
            connectionError: false,
        }
    },
    computed: {
        offline() {
            return this.connectionError
        }
    },
    watch: {
        newContentReady(newVal) {
            if (newVal) {
                this.$refs.appVersionModal.show();
            }
        },
        showAppVersionModal(newVal) {
            if (newVal && this.newContentReady) {
                this.$refs.appVersionModal.show();
                this.newContentReady = false;
            }
        }
    },
    created() {
        if (!location.host.includes('family')) {
            document.querySelector('html').classList.add('default')
        } else {
            document.querySelector('html').classList.add('gfs')
        }

        addEventListener('content-ready', () => {
            this.newContentReady = true;
        })

        setInterval(() => {
            let cancelTokenSource = axios.CancelToken.source();

            axios.get(`${process.env.VUE_APP_API_URL}api/online`, {
                cancelToken: cancelTokenSource.token
            }).then(() => {
                this.connectionError = false;
            }).catch(() => {
                this.connectionError = true;
            });

            setTimeout(() => {
                cancelTokenSource.cancel();
            }, 5000)
        }, 10000)
    },
    mounted() {
        this.isLoggedIn = (this.$store.state.user.usid !== null)
        this.loadGoogleApi()
        this.checkVersion()
    },
    methods: {
        displayMenu() {
            this.showMenu = !this.showMenu
            if (this.$refs.sideMenu) {
                this.$refs.sideMenu.getPointageEnCours()
                if (this.showMenu) {
                    this.checkVersion()
                    if (this.$refs.sideMenu.user === null) {
                        this.$refs.sideMenu.loadUser()
                    }
                }
            }
        },
        setMenu(menu) {
            this.selectedMenu = menu
        },
        loginHandler() {
            this.isLoggedIn = true
            if (this.$refs.sideMenu) {
                this.$refs.sideMenu.loadUser()
            }
            if (this.$refs.header) {
                this.$refs.header.loadUser()
            }
        },
        loadGoogleApi() {
            let balise = document.createElement('script')
            balise.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAuMgFOKqWt5j1jfNO_curYewlpLxUZKWw&libraries=visualization,places&callback=Function.prototype'
            balise.async = true
            balise.defer = true
            document.head.appendChild(balise)
        },
        checkVersion() {
            appService.getAppVersion().then((response) => {
                if (response.data.etat === 'OK') {
                    this.version = response.data.data
                    if (this.version !== require('../package').version) {
                        this.$refs.appVersionModal.show();
                    }
                } else {
                    this.$rollbar.error('Erreur 3001');
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3001',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3004', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3004',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        }
    }
}
</script>

<style>
@media (min-width: 992px) {
    #sideMenu {
        display: none;
    }

    #components_application {
        margin-top: 70px;
    }
}

#sideMenu {
    position: fixed;
    z-index: 3;
    right: -1000px;
    transition: right 0.6s;
    top: 45px;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    height: calc(100% - 96px);
    overflow-y: auto;
}

#sideMenu.sideMenu-shown {
    right: 0;
    transition: right 0.6s;
}

#components_application {
    padding: 0 15px;
}

.grecaptcha-badge {
    display: none !important;
}
</style>
