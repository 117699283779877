<template>
    <b-modal ref="appVersionModal" id="appVersionModal" no-close-on-backdrop no-close-on-esc>
        <template v-slot:modal-header>
            <div class="modal-title text-center w-100 bg-info text-white p-3">
                <span class="font-weight-bold m-0 h4">Une nouvelle version de l'application est disponible</span>
            </div>
        </template>
        <template v-slot:default>
            <b-container fluid>
                <b-row>
                    <b-col sm="12">Afin de profiter des dernières fonctionnalités et des derniers correctifs, nous vous
                        invitons à actualiser votre application pour la version {{ version }}.
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <template v-slot:modal-footer>
            <button type="submit" class="btn btn-lg btn-success w-100 m-0 no-top-border-radius" @click="validate">
                <span><i class="far fa-calendar-alt mr-2"></i>Actualiser</span>
            </button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "AppVersionModal",
    props: {
        version: {
            default: '0.0.0'
        }
    },
    methods: {
        hide() {
            this.$refs.appVersionModal.hide()
        },
        show() {
            //On affiche la popup seulement une fois par jour maximum
            let datePopUp = localStorage.getItem('dateAffichagePopUpVersion')
            if (datePopUp === null) {
                localStorage.setItem('dateAffichagePopUpVersion', this.$moment().format('YYYY-MM-DD'))
                this.$refs.appVersionModal.show()
            } else if (datePopUp != this.$moment().format('YYYY-MM-DD')) {
                localStorage.setItem('dateAffichagePopUpVersion', this.$moment().format('YYYY-MM-DD'))
                this.$refs.appVersionModal.show()
            }
        },
        validate() {
            console.log("mise à jour");
            location.href = "/mise-a-jour"
        }
    }
}
</script>

<style scoped>
/deep/ #appVersionModal .modal-header,
/deep/ #appVersionModal .modal-footer,
/deep/ #appVersionModal .container-fluid {
    padding: 0;
}
</style>
