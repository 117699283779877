import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: function (resolve) {
                require(['./components/Authentication/Login.vue'], resolve)
            },
            props: true
        },
        {
            path: '/admin',
            name: 'admin',
            component: function (resolve) {
                require(['./components/Authentication/Admin.vue'], resolve)
            },
            props: true
        },
        {
            path: '/forgotten-password',
            name: 'forgotten-password',
            component: function (resolve) {
                require(['./components/Authentication/ForgottenPassword.vue'], resolve)
            },
            props: true
        },
        {
            path: '/register',
            name: 'register',
            component: function (resolve) {
                require(['./components/Authentication/Register.vue'], resolve)
            },
            props: true
        },
        {
            path: '',
            name: 'home',
            component: function (resolve) {
                require(['./components/Pages/Home/Home.vue'], resolve)
            },
            props: route => ({actionName: route.query.name, actionData: route.query.data, notifId: route.query.id}),
            beforeEnter: guardRoute
        },
        {
            path: '/detection',
            name: 'detection',
            component: function (resolve) {
                require(['./components/Pages/Detection.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/intervention/:idIntervention',
            name: 'intervention',
            component: function (resolve) {
                require(['./components/Pages/Intervention/Intervention.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/mission/:clientId',
            name: 'mission',
            component: function (resolve) {
                require(['./components/Pages/Mission.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/pointages',
            name: 'pointages',
            component: function (resolve) {
                require(['./components/Pages/Pointages.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/declaration-prestations/:mode',
            name: 'declarationPrestations',
            component: function (resolve) {
                require(['./components/Pages/DeclarationPrestations.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/clients',
            name: 'clients',
            component: function (resolve) {
                require(['./components/Pages/Clients.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/clients/:clientId/:intervAdress?',
            name: 'fiche-client',
            component: function (resolve) {
                require(['./components/Pages/FicheClient.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/clients/:clientId/consignes',
            name: 'consignes',
            component: function (resolve) {
                require(['./components/Pages/Consignes/Consignes.vue'], resolve)
            },
            props: true,
            beforeEnter: guardRoute
        },
        {
            path: '/infos',
            name: 'infos',
            component: function (resolve) {
                require(['./components/Pages/Infos.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/agenda',
            name: 'agenda',
            component: function (resolve) {
                require(['./components/Pages/Agenda/Agenda.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/declaration-tardive',
            name: 'declaration-tardive',
            component: function (resolve) {
                require(['./components/Pages/DeclarationTardive/DeclarationTardive.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/missions-disponibles',
            name: 'missions-disponibles',
            component: function (resolve) {
                require(['./components/Pages/MissionsDisponibles/MissionsDisponibles.vue'], resolve)
            },
            beforeEnter: guardRoute
        },
        {
            path: '/index.html',
            name: 'home',
            component: function (resolve) {
                require(['./components/Pages/Home/Home.vue'], resolve)
            },
            props: route => ({actionName: route.query.name, actionData: route.query.data, notifId: route.query.id}),
            beforeEnter: guardRoute
        },
        {
            path: '*',
            redirect: '/login'
        }
    ],

})

function guardRoute(to, from, next) {
    const auth = store.state.auth
    if (!auth.isLoggedIn) {
        next({
            name: 'login'
        })
    } else {
        next()
    }
}

